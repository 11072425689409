import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { getBottomCenter, getCenter } from '../utils';
import { action } from 'mobx';

const inactiveScale = 0.6;
const inactiveRotation = 70;

let nextId = 0;

const square = x => x * x;

const distance = (a, b) => Math.sqrt(square(a.x - b.x) + square(a.y - b.y));

const snapDistance = 50;

export const Draggable = observer(({ position, children }) => {
	const id = useMemo(() => 'draggable-' + nextId++, []);
	const snaps = position.snaps ?? [];

	useEffect(() => {
		const onMouseDown = event => {
			// Calculate the mouse position in degrees
			position.downPosition = {
				x: position.x - event.pageX,
				y: position.y - event.pageY,
			};
		};

		const onMouseUp = action('mouseup', () => {
			if (!position.active) {
				position.x = position.initial.x;
				position.y = position.initial.y;
			}

			if (snaps?.length > 0) {
				for (const snap of snaps) {
					snap.connected?.remove(id);
				}

				const distances = snaps
					.map(snap => ({ snap, d: distance(position, snap) }))
					.sort((a, b) => a.d - b.d);
				if (distances[0].d < snapDistance) {
					position.x = distances[0].snap.x;
					position.y = distances[0].snap.y;
					distances[0].snap.connected ??= [];
					distances[0].snap.connected.push(id);
				}
			}
			position.downPosition = null;
		});

		const onMouseMove = action('mousemove', event => {
			if (!position.downPosition) {
				return;
			}
			position.x = position.downPosition.x + event.pageX;
			position.y = position.downPosition.y + event.pageY;
			position.active =
				position.x > 10 &&
				position.x < 790 &&
				position.y > 10 &&
				position.y < position.initial.y - 50;
		});

		const item = document.querySelector('#' + id);

		if (window.ontouchstart) {
			item.addEventListener('touchstart', onMouseDown);
			window.addEventListener('touchend', onMouseUp);
			window.addEventListener('touchmove', onMouseMove);

			return () => {
				item.removeEventListener('touchstart', onMouseDown);
				window.removeEventListener('touchend', onMouseUp);
				window.removeEventListener('touchmove', onMouseMove);
			};
		}

		item.addEventListener('mousedown', onMouseDown);
		window.addEventListener('mouseup', onMouseUp);
		window.addEventListener('mousemove', onMouseMove);

		return () => {
			item.removeEventListener('mousedown', onMouseDown);
			window.removeEventListener('mouseup', onMouseUp);
			window.removeEventListener('mousemove', onMouseMove);
		};
	}, []);

	useEffect(() => {
		const bottom = getBottomCenter(document.querySelector('#' + id));
		position.bottom.x = bottom.x;
		position.bottom.y = bottom.y;
	});

	const scale = position.active ? 1 : 0.6;
	const rotation = position.active ? 0 : 70;

	return (
		<>
			<div
				id={id}
				style={{
					position: 'absolute',
					left: position.x,
					top: position.y,
					zIndex: 100,
					transition: 'transform 0.15s ease-in-out',
					transform: `scale(${scale}) rotate(${rotation}deg)`,
				}}
			>
				{children}
			</div>
			{snaps.map((snap, index) => (
				<div
					id={`${id}-snap-${index}`}
					style={{
						position: 'absolute',
						left: snap.x,
						top: snap.y,
						zIndex: 95,
						opacity: position.downPosition
							? distance(position, snap) < snapDistance
								? 0.7
								: 0.4
							: 0,
						transition: 'opacity 0.15s linear',
					}}
				>
					{children}
				</div>
			))}
			<div
				id={`${id}-initial`}
				style={{
					position: 'absolute',
					left: position.initial.x,
					top: position.initial.y,
					zIndex: 95,
					opacity: 0.2,
					transition: 'transform 0.15s ease-in-out',
					transform: `scale(${inactiveScale}) rotate(${inactiveRotation}deg)`,
				}}
			>
				{children}
			</div>
		</>
	);
});

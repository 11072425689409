import { observer } from 'mobx-react-lite';
import React from 'react';
import { Draggable } from '../Draggable';
import { Wire } from '../Wire';
import { ReactComponent as Port } from './port.svg';
import { ReactComponent as Probe } from './probe.svg';

export const BlackProbe = observer(({ state }) => {
	return (
		<>
			<Wire color="#343331" from={state.probes.black.port} to={state.probes.black.pin} />
			<Draggable position={state.probes.black.port}>
				<Port className="probe-port" />
			</Draggable>
			<Draggable position={state.probes.black.pin}>
				<Probe className="probe-pin" />
			</Draggable>
		</>
	);
});

import React from 'react';
import './styles/app.css';
import { Multimeter } from './components/Multimeter';
import { RedProbe } from './components/RedProbe';
import { BlackProbe } from './components/BlackProbe';
import { DistributionBoard } from './components/DistributionBoard';
import { configure, observable, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { MeterDisplay } from './components/MeterDisplay';

configure({ enforceActions: 'never' });

const initialPosition = (x, y, snaps) => ({
	x,
	y,
	bottom: { x, y },
	initial: { x, y },
	active: false,
	snaps,
});

const connections = observable({
	redPort: { x: 53, y: 297 },
	greenPort: { x: 26, y: 270 },
	blackPort: { x: 103, y: 297 },
	pointA: { x: 380, y: 250 },
	pointB: { x: 530, y: 250 },
	pointC: { x: 610, y: 145 },
	pointD: { x: 340, y: 132 },
});

const allPoints = [connections.pointA, connections.pointB, connections.pointC, connections.pointD];

const state = observable({
	connections,
	rotation: 0,
	dialCenter: { x: 0, y: 0 },
	ports: {
		white: null,
		red: null,
		green: null,
	},
	probes: {
		red: {
			port: initialPosition(110, 510, [connections.redPort, connections.greenPort]),
			pin: initialPosition(220, 450, allPoints),
		},
		black: {
			port: initialPosition(330, 510, [connections.blackPort]),
			pin: initialPosition(440, 450, allPoints),
		},
	},
});

window.state = state;
window.toJS = toJS;

function App() {
	return (
		<div className="App">
			<div>
				<Multimeter state={state} />
				<DistributionBoard state={state} />
			</div>
			<RedProbe state={state} />
			<BlackProbe state={state} />
			<div className="toolbox">
				<img src="/toolbox.png" alt="toolbox" />
			</div>
		</div>
	);
}

export default observer(App);

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { getCenter, getDegrees } from '../../utils';
import { ReactComponent as MultimeterIcon } from './Multimeter.svg';
import { MeterDisplay } from '../MeterDisplay';

export const Multimeter = observer(({ state }) => {
	useEffect(() => {
		const item = document.querySelector('.MultimeterIcon');
		state.dialCenter = getCenter(item.querySelector('.arrow'));
		state.ports.white = getCenter(item.querySelector('.white-port'));
		state.ports.red = getCenter(item.querySelector('.red-port'));
		state.ports.green = getCenter(item.querySelector('.green-port'));
	}, []);

	useEffect(() => {
		let mouseDownDegrees;

		const onMouseDown = event => {
			// Calculate the mouse position in degrees
			mouseDownDegrees =
				state.rotation -
				getDegrees(event.pageX, event.pageY, state.dialCenter.x, state.dialCenter.y);
		};

		const onMouseUp = () => {
			mouseDownDegrees = null;
		};

		const onMouseMove = event => {
			if (!mouseDownDegrees) {
				return;
			}
			const degrees = getDegrees(
				event.pageX,
				event.pageY,
				state.dialCenter.x,
				state.dialCenter.y,
			);
			state.rotation = mouseDownDegrees + degrees;
		};

		const multimeter = document.querySelector('.MultimeterIcon');
		multimeter.addEventListener('mousedown', onMouseDown);
		document.addEventListener('mouseup', onMouseUp);
		document.addEventListener('mousemove', onMouseMove);

		return () => {
			multimeter.removeEventListener('mousedown', onMouseDown);
			document.removeEventListener('mouseup', onMouseUp);
			document.removeEventListener('mousemove', onMouseMove);
		};
	}, []);

	return (
		<div className="multimeter">
			<MultimeterIcon
				className="MultimeterIcon"
				style={{ '--multimeter-rotation': `${state.rotation}deg` }}
			/>
			<MeterDisplay state={state} />
		</div>
	);
});

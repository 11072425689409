export const getCenter = elem => {
	const rect = elem.getBoundingClientRect();
	return {
		x: rect.left + rect.width / 2 + window.scrollX,
		y: rect.top + rect.height / 2 + window.scrollY,
	};
};

export const getBottomCenter = elem => {
	const rect = elem.getBoundingClientRect();
	return {
		x: rect.left + rect.width / 2 + window.scrollX,
		y: rect.bottom + window.scrollY,
	};
};

export const getDegrees = (mouse_x, mouse_y, center_x, center_y) => {
	const radians = Math.atan2(mouse_x - center_x, mouse_y - center_y);
	const degrees = Math.round(-radians * (180 / Math.PI));

	return degrees;
};

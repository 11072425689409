import React from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';

// redPort: { x: 53, y: 297 },
// greenPort: { x: 26, y: 270 },
// blackPort: { x: 103, y: 297 },
// pointA: { x: 380, y: 250 },
// pointB: { x: 530, y: 250 },
// pointC: { x: 610, y: 145 },
// pointD: { x: 340, y: 132 },

export const MeterDisplay = observer(({ state }) => {
	toJS(state);
	const connected = Object.fromEntries(
		Object.entries(state.connections)
			.map(([key, value]) => [key, value.connected?.length > 0])
			.filter(([key, value]) => value),
	);

	if (connected.blackPort && connected.redPort) {
		if (connected.pointA && connected.pointB) {
			return <span>130</span>;
		}

		if (connected.pointA && connected.pointC) {
			return <span>45</span>;
		}

		if (connected.pointA && connected.pointD) {
			return <span>17</span>;
		}

		if (connected.pointB && connected.pointC) {
			return <span>2</span>;
		}

		if (connected.pointB && connected.pointD) {
			return <span>984</span>;
		}

		if (connected.pointC && connected.pointD) {
			return <span>15</span>;
		}
	}

	if (connected.blackPort && connected.greenPort) {
		if (connected.pointA && connected.pointB) {
			return <span>0.130</span>;
		}

		if (connected.pointA && connected.pointC) {
			return <span>0.045</span>;
		}

		if (connected.pointA && connected.pointD) {
			return <span>0.017</span>;
		}

		if (connected.pointB && connected.pointC) {
			return <span>0.002</span>;
		}

		if (connected.pointB && connected.pointD) {
			return <span>0.984</span>;
		}

		if (connected.pointC && connected.pointD) {
			return <span>0.015</span>;
		}
	}

	return <span>err</span>;
});

import { observer } from 'mobx-react-lite';

export const Wire = observer(({ from, to, color }) => {
	const start = from.bottom;
	const end = to.bottom;
	const ref1 = {
		x: start.x,
		y: Math.max(start.y, end.y) + 200,
	};
	const ref2 = {
		x: end.x,
		y: Math.max(start.y, end.y) + 200,
	};
	const opacity = from.active && to.active ? 1 : 0;

	return (
		<svg
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				pointerEvents: 'none',
				zIndex: 90,
			}}
			fill="none"
			stroke={color}
			strokeWidth="8"
		>
			<path
				d={`
					M ${start.x} ${start.y - 5}
					C ${ref1.x} ${ref1.y}
					${ref2.x} ${ref2.y}
					${end.x} ${end.y - 5}
				`}
				style={{
					opacity,
					transition: 'opacity 0.15s linear',
				}}
			/>
		</svg>
	);
});
